import React from 'react';
import { BrowserRouter as Router, Routes as Switch, Route, Link } from 'react-router-dom'
import Lida from './pages/LidaDefault';
import LidaStart from './pages/LidaStart';

function App() {
  return (
    <Router>
      <Switch>
        <Route path='/app' Component={Lida}/>
        <Route path='/' Component={LidaStart}></Route>
      </Switch>
    </Router>
  );
}

export default App;
