

import React, { useEffect, useState } from 'react'
// import { translate } from '@vitalets/google-translate-api';
import axios from 'axios'
import './LidaDefault.css'

const languageFullNames: { [key: string]: string } = {
  'det' : 'Detect Language', 'af': 'Afrikaans', 'sq': 'Albanian', 'am': 'Amharic', 'ar': 'Arabic', 'hy': 'Armenian', 'az': 'Azerbaijani', 'eu': 'Basque',
  'be': 'Belarusian', 'bn': 'Bengali', 'bs': 'Bosnian', 'bg': 'Bulgarian', 'ca': 'Catalan', 'ceb': 'Cebuano', 'ny': 'Chichewa',
  'zh-CN': 'Chinese (Simplified)', 'zh-TW': 'Chinese (Traditional)', 'co': 'Corsican', 'hr': 'Croatian', 'cs': 'Czech',
  'da': 'Danish', 'nl': 'Dutch', 'en': 'English', 'eo': 'Esperanto', 'et': 'Estonian', 'tl': 'Filipino', 'fi': 'Finnish',
  'fr': 'French', 'fy': 'Frisian', 'gl': 'Galician', 'ka': 'Georgian', 'de': 'German', 'el': 'Greek', 'gu': 'Gujarati',
  'ht': 'Haitian Creole', 'ha': 'Hausa', 'haw': 'Hawaiian', 'iw': 'Hebrew', 'hi': 'Hindi', 'hmn': 'Hmong', 'hu': 'Hungarian',
  'is': 'Icelandic', 'ig': 'Igbo', 'id': 'Indonesian', 'ga': 'Irish', 'it': 'Italian', 'ja': 'Japanese', 'jw': 'Javanese',
  'kn': 'Kannada', 'kk': 'Kazakh', 'km': 'Khmer', 'rw': 'Kinyarwanda', 'ko': 'Korean', 'ku': 'Kurdish', 'ky': 'Kyrgyz',
  'lo': 'Lao', 'la': 'Latin', 'lv': 'Latvian', 'lt': 'Lithuanian', 'lb': 'Luxembourgish', 'mk': 'Macedonian', 'mg': 'Malagasy',
  'ms': 'Malay', 'ml': 'Malayalam', 'mt': 'Maltese', 'mi': 'Maori', 'mr': 'Marathi', 'mn': 'Mongolian', 'my': 'Burmese',
  'ne': 'Nepali', 'no': 'Norwegian', 'ps': 'Pashto', 'fa': 'Persian', 'pl': 'Polish', 'pt': 'Portuguese', 'pa': 'Punjabi',
  'ro': 'Romanian', 'ru': 'Russian', 'sm': 'Samoan', 'gd': 'Scots Gaelic', 'sr': 'Serbian', 'st': 'Sesotho', 'sn': 'Shona',
  'sd': 'Sindhi', 'si': 'Sinhala', 'sk': 'Slovak', 'sl': 'Slovenian', 'so': 'Somali', 'es': 'Spanish', 'su': 'Sundanese',
  'sw': 'Swahili', 'sv': 'Swedish', 'tg': 'Tajik', 'ta': 'Tamil', 'te': 'Telugu', 'th': 'Thai', 'tr': 'Turkish', 'tk': 'Turkmen',
  'uk': 'Ukrainian', 'ur': 'Urdu', 'ug': 'Uyghur', 'uz': 'Uzbek', 'vi': 'Vietnamese', 'cy': 'Welsh', 'xh': 'Xhosa',
  'yi': 'Yiddish', 'yo': 'Yoruba', 'zu': 'Zulu'
};

export default function Lida() {
  const [translationInput, setTranslationInput] = useState<any>('')
  const [translationOutput, setTranslationOutput] = useState<any>('')
  const [fromLang, setFromLang] = useState<any>('it')
  const [toLang, setToLang] = useState<any>('en')
  const [langActive, setLangActive] = useState<any>('')
  const [loading, setLoading] = useState(true)

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     // setTimeout(forceUpdate, Math.random() * 5000)
  //     translate()
      
  //   }, 1000 );

  //   return () => clearInterval(interval);
  // }, [])

  useEffect(() => {
    setTimeout(() => {
      setLoading(false)
    }, 2000)
  }, [])

  const translate = async () => {
    if (translationInput) {
      const sourceLang = fromLang;
      const targetLang = toLang;
      try {
        axios.get(
          `https://translate.googleapis.com/translate_a/single?client=gtx&sl=${sourceLang}&tl=${targetLang}&dt=t&q=${encodeURI(
            translationInput.replaceAll('\n', '_')
          )}`
        ).then((response:any) => setTranslationOutput(response.data[0][0][0].replaceAll('_', '\n')))
      } catch (error) {
        console.error('Error translating:', error);
      }
    } else {
      setTranslationOutput('')
    }
  };

  function evaluate_behavior_language(lang:string) {
    if (langActive === 'from') {
      if (fromLang === lang) {
        return 'active'
      } else {
        if (toLang === lang) {
          return 'hidden'
        } else {
          return 'normal'
        }
      }
    } else {
      if (toLang === lang) {
        return 'active'
      } else {
        if (fromLang === lang) {
          return 'hidden'
        } else {
          return 'normal'
        }
      }
    }
  }


  return (
    <div className='lida'>
      {
        loading ? <div className="loading" style={{opacity: (loading ? '1' : '0'), pointerEvents: (loading ? 'auto' : 'none')}}>
          <svg xmlns="http://www.w3.org/2000/svg" width="72" height="73" viewBox="0 0 72 73" fill="none">
            <path d="M21.7676 4.99617C7.90008 26.8813 16.5717 62.2844 42.7406 70.3126M21.7676 5.41991C48.9669 7.83754 62.7812 39.7208 56.5231 63.7209M21.468 4.99617L50.2311 67.9155M6.48716 19.3777C20.6791 26.8801 38.4653 16.9842 44.2388 3.19861M2.59214 34.6582C23.4411 41.3464 48.7347 30.9226 57.7215 10.6889M5.28869 51.4367C28.7266 57.224 57.4124 46.7442 66.71 23.2728M69.3967 36.1894C69.5099 45.3498 66.0812 54.1801 59.8625 60.7445C53.6438 67.3088 45.1425 71.0714 36.2223 71.2075C27.3022 71.3436 18.6911 67.8419 12.277 61.4703C5.86294 55.0987 2.16922 46.377 2.00567 37.2174C1.84212 28.0578 5.22209 19.2077 11.4046 12.6073C17.587 6.00702 26.0675 2.1951 34.9867 2.00728C43.906 1.81946 52.5361 5.27107 58.9851 11.6054C65.4341 17.9397 69.1758 26.6399 69.3897 35.7984M14.5768 63.7209C35.4056 71.0535 61.5583 60.2031 69.4065 39.1523M69.027 41.7135C69.3419 39.5558 69.4575 37.3725 69.3722 35.1927" stroke="#0000FF" stroke-width="3.38977"/>
          </svg>
        </div> : <></>
      }
      <div className="navigator">
        <div className="logo">
          <div className="top" onClick={translate}>
            <svg xmlns="http://www.w3.org/2000/svg" width="72" height="74" viewBox="0 0 72 74" fill="none">
              <path d="M21.7676 5.3907C7.90008 27.2758 16.5717 62.6789 42.7406 70.7071M21.7676 5.81445C48.9669 8.23207 62.7812 40.1153 56.5231 64.1155M21.468 5.3907L50.2311 68.3101M6.48716 19.7723C20.6791 27.2747 38.4653 17.3787 44.2388 3.59314M2.59214 35.0527C23.4411 41.7409 48.7347 31.3171 57.7215 11.0834M5.28869 51.8312C28.7266 57.6185 57.4124 47.1387 66.71 23.6673M69.3967 36.584C69.5099 45.7443 66.0812 54.5747 59.8625 61.139C53.6438 67.7033 45.1425 71.466 36.2223 71.602C27.3022 71.7381 18.6911 68.2365 12.277 61.8649C5.86294 55.4932 2.16922 46.7715 2.00567 37.6119C1.84212 28.4523 5.22209 19.6022 11.4046 13.0019C17.587 6.40155 26.0675 2.58963 34.9867 2.40181C43.906 2.214 52.5361 5.6656 58.9851 11.9999C65.4341 18.3343 69.1758 27.0344 69.3897 36.193M14.5768 64.1155C35.4056 71.448 61.5583 60.5976 69.4065 39.5469M69.027 42.1081C69.3419 39.9504 69.4575 37.767 69.3722 35.5872" stroke="#0000FF" stroke-width="3.38977"/>
            </svg>
            <h1>LIDA</h1>
          </div>
          <h2>Translating Worlds, Connecting Hearts.</h2>
        </div>
      </div>
      <div className="translator">
        <div className="bar">
          <div className="selector" onClick={() => setLangActive(langActive ? '' : 'from')} style={{background: (langActive === 'from' ? '#CBD5E1' : '')}}>
            { fromLang === 'det' ? <svg xmlns="http://www.w3.org/2000/svg" width="18" height="19" viewBox="0 0 18 19" fill="none">
              <path d="M7.3599 12.4279L6.75 14.5625L6.1401 12.4279C5.81976 11.3067 4.94334 10.4302 3.82214 10.1099L1.6875 9.5L3.82214 8.8901C4.94334 8.56976 5.81976 7.69334 6.1401 6.57214L6.75 4.4375L7.3599 6.57214C7.68024 7.69334 8.55666 8.56976 9.67786 8.8901L11.8125 9.5L9.67786 10.1099C8.55666 10.4302 7.68024 11.3067 7.3599 12.4279Z" stroke="#94A3B8" stroke-width="1.125" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M13.6941 7.0359L13.5 7.8125L13.3059 7.0359C13.0791 6.12899 12.371 5.42088 11.4641 5.19415L10.6875 5L11.4641 4.80585C12.371 4.57912 13.0791 3.87101 13.3059 2.9641L13.5 2.1875L13.6941 2.9641C13.9209 3.87101 14.629 4.57912 15.5359 4.80585L16.3125 5L15.5359 5.19415C14.629 5.42088 13.9209 6.12899 13.6941 7.0359Z" stroke="#94A3B8" stroke-width="1.125" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M12.6707 15.9255L12.375 16.8125L12.0793 15.9255C11.9114 15.4216 11.5159 15.0261 11.012 14.8582L10.125 14.5625L11.012 14.2668C11.5159 14.0989 11.9114 13.7034 12.0793 13.1995L12.375 12.3125L12.6707 13.1995C12.8386 13.7034 13.2341 14.0989 13.738 14.2668L14.625 14.5625L13.738 14.8582C13.2341 15.0261 12.8386 15.4216 12.6707 15.9255Z" stroke="#94A3B8" stroke-width="1.125" stroke-linecap="round" stroke-linejoin="round"/>
            </svg> : <></>}
            <h3>{languageFullNames[fromLang]}</h3>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
              <path d="M4 6.5L8 10.5L12 6.5" stroke="#94A3B8" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </div>
          <div className="swap" onClick={() => {
            if (fromLang !== 'det') {
              const f = fromLang
              setFromLang(toLang)
              setToLang(f)
              const c = translationInput
              setTranslationInput(translationOutput)
              setTranslationOutput(c)
            }
          }}>
            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="9" viewBox="0 0 14 9" fill="none">
              <path d="M11 5V3.5H5V1.5H11V0L14 2.5L11 5Z" fill="#CBD5E1"/>
              <path d="M3 9V7.5H9V5.5H3V4L0 6.5L3 9Z" fill="#CBD5E1"/>
            </svg>
          </div>
          <div className="selector" onClick={() => setLangActive(langActive ? '' : 'to')} style={{background: (langActive === 'to' ? '#CBD5E1' : '')}}>
            <h3>{languageFullNames[toLang]}</h3>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
              <path d="M4 6.5L8 10.5L12 6.5" stroke="#94A3B8" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </div>
        </div>
        {
          // possible state, active state, hidden state (already used on other side)
          langActive ? <div className='shift'>
          {Object.keys(languageFullNames).map((language:any) => (
            ((language!=='det') || (langActive==='from')) ? <div className={"prop " + evaluate_behavior_language(language)} key={language} onClick={() => {
              if (langActive === 'from') {
                if (evaluate_behavior_language(language) === 'normal') {
                  setFromLang(language)
                  setLangActive('')
                }
              } 
              if (langActive === 'to') {
                if (evaluate_behavior_language(language) === 'normal') {
                  setToLang(language)
                  setLangActive('')
                }
              }
            }}>
              <h3>{languageFullNames[language]}</h3>
            </div> : <></>
          ))}
          <div className="prop">
            
          </div>
          <div className="prop">
            
          </div>
          <div className="prop">
            
          </div>
          
          </div> : <></>
        }
        <div style={{height: 10}}></div>
        <div className="spare">
          <div className="left">
            <textarea value={translationInput} name=""  placeholder='Input' onChange={(e) => {
              setTranslationInput(e.target.value);
              // translate()
            }}></textarea>
          </div>
          <div className="tz" onClick={translate}>
            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 20 20" fill="none">
              <path d="M7.75009 2.75C7.75009 2.33579 7.4143 2 7.00009 2C6.58587 2 6.25009 2.33579 6.25009 2.75V4.00842C5.03323 4.03578 3.8325 4.12954 2.65142 4.28627C2.24081 4.34076 1.95211 4.7178 2.0066 5.12841C2.06109 5.53902 2.43813 5.82772 2.84875 5.77323C4.20658 5.59304 5.59228 5.5 7.00009 5.5C7.57065 5.5 8.13758 5.51528 8.70048 5.54546C8.2826 6.95686 7.70916 8.30168 6.99996 9.56001C6.62755 8.89925 6.29259 8.21466 5.99793 7.50912C5.8383 7.1269 5.39905 6.94646 5.01683 7.10608C4.63461 7.26571 4.45416 7.70497 4.61379 8.08718C5.03667 9.09975 5.5365 10.072 6.10593 10.9967C5.03656 12.5598 3.74539 13.9596 2.27753 15.1506C1.95588 15.4116 1.9067 15.8839 2.16769 16.2055C2.42867 16.5272 2.90099 16.5764 3.22264 16.3154C4.64811 15.1588 5.91919 13.8189 6.99996 12.3314C7.0954 12.4628 7.19233 12.593 7.29071 12.722C7.54187 13.0514 8.01249 13.1148 8.34187 12.8637C8.67125 12.6125 8.73467 12.1419 8.48351 11.8125C8.2802 11.5459 8.08361 11.2739 7.894 10.9967C8.90466 9.35554 9.69602 7.56455 10.227 5.66453C10.5365 5.69627 10.8447 5.73253 11.1514 5.77323C11.562 5.82772 11.9391 5.53902 11.9936 5.12841C12.0481 4.7178 11.7594 4.34076 11.3487 4.28627C10.8114 4.21496 10.27 4.15669 9.72489 4.11178C9.07179 4.05797 8.41333 4.02333 7.75009 4.00842V2.75Z" fill="black"/>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M13.0002 8C13.2842 8 13.5439 8.1605 13.671 8.41459L17.921 16.9146C18.1062 17.2851 17.9561 17.7356 17.5856 17.9208C17.2151 18.1061 16.7646 17.9559 16.5793 17.5854L15.7866 16H10.2137L9.42098 17.5854C9.23574 17.9559 8.78524 18.1061 8.41475 17.9208C8.04427 17.7356 7.8941 17.2851 8.07934 16.9146L12.3293 8.41459C12.4564 8.1605 12.7161 8 13.0002 8ZM15.0366 14.5L13.0002 10.4271L10.9637 14.5H15.0366Z" fill="black"/>
            </svg>
            {/* <h3>TRANSLATE</h3> */}
          </div>
          <div className="right">
            <textarea value={translationOutput} name="" placeholder='Output' onChange={(e) => {
              
              setTranslationOutput(e.target.value);
            }}></textarea>
          </div>
        </div>
        <div style={{height: 10}}></div>
      </div>
      <div className="schemas">
        
      </div>
      <div className="paragraph">
        <h2>THE MOST ACCURATE TRANSLATION IN THE WORLD</h2>
        <p>Unparalleled Quality Driven by Advanced Artificial Intelligence Our trailblazing innovations have positioned us as a cornerstone in our field, propelling us towards our ultimate goal: a world unburdened by language barriers.<br></br>With more than a billion users opting for DeepL, our translation quality stands as a testament to the groundbreaking artificial intelligence driving our platform.<br></br>The World's Leading Automated Translation DeepL's neural networks possess an uncanny ability to detect even the most subtle nuances of language, seamlessly incorporating them into translations unlike any other service. To assess the strength of our automated translation models, we consistently conduct blind tests. In these evaluations, professional translators select the best translation without knowledge of the originating company. DeepL outperforms the competition with an impressive 3:1 margin.</p>
      </div>
      <div className="modles">
        
      </div>
      <div className="ready">
        <h2>READY TO TRANSLATE?</h2>
      </div>
    </div>
  )
}

/*
TODO: advanced output, use divs (deepl like)
TODO: 

*/