
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import './LidaStart.css'

function useForceUpdate(){
  const [value, setValue] = useState(0); // integer state
  return () => setValue(value => value + 1); // update state to force render
  // A function that increment 👆🏻 the previous state like here 
  // is better than directly setting `setValue(value + 1)`
}

function range(__start:number, __end:number) {
  let array = []
  for (var i = __start; i < __end; i++) {
    array.push(i)
  }
  return array
}

export default function LidaStart() {
  const forceUpdate = useForceUpdate();
  const [selectedIndexPage, setSelectedIndexPage] = useState<number>(0)
  const numberOfPages = 3
  // const [page1Text, setPage1Text] = useState('Welcome Lida')
  // const [page1TextDirection, setPage1TextDirection] = useState(false)

  useEffect(() => {
    const interval = setInterval(() => {
      forceUpdate()
      if (selectedIndexPage >= (numberOfPages - 1)) {setSelectedIndexPage(0)} else { setSelectedIndexPage(selectedIndexPage + 1)}
    }, 5000 );

    return () => clearInterval(interval);
  }, [selectedIndexPage])

  // useEffect(() => {
  //   const new_interval = setInterval(() => {
  //     forceUpdate()
  //     const default_text = 'Welcome Lida'

  //     let direction = 0

  //     if (page1Text === default_text) { 
  //       direction = -1;
  //     } if (page1Text === '') { 
  //       direction = +1 
  //     }

  //     setPage1TextDirection(direction === +1)

  //     if (page1TextDirection === true) {
  //       setPage1Text(page1Text + default_text[page1Text.length-1])
  //     } else {
  //       const slic = page1Text.substring(0, page1Text.length - 1)
  //       setPage1Text(slic)
  //     }
  //     console.log(page1TextDirection)
  //   }, 50 );

  //   return () => clearInterval(new_interval);
  // }, [])

  return (
    <div className='lida-start'>
      <div className={"lida-show page" + (selectedIndexPage+1)}>
        {
          selectedIndexPage === 0 ? <div style={{marginBottom: 100}}>
            <h3>neat</h3>
            <h2>Welcome Lida</h2>
          </div> : selectedIndexPage === 1 ? <div style={{marginBottom: 100}}>
            <h3>translate</h3>
            <h2>Anywhere, Anytime</h2>
          </div> : selectedIndexPage === 2 ? <div style={{marginBottom: 100}}>
            <h3>are you ready?</h3>
            <h2>Translate?</h2>
            <Link to='/app' className="button-show">
              <h4>Translate</h4>
            </Link>
          </div> : <></>
        }
        <div className="carousel-indexes">
          {
            range(0, numberOfPages).map((p:number) => {
              return <div onClick={() => setSelectedIndexPage(p)} className={'carousel-index '  + (p === selectedIndexPage ? 'selected' : '')}>

              </div>
            })
          }
        </div>
      </div>
    </div>
  )
}